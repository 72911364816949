import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Callback = ({setIsAuthenticated}) => {
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleLineCallback = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get("code"); // ดึงค่า `code` จาก Query Parameter
        //console.log("Code in React:", code); // Log ค่า `code`

        if (code) {
            try {
                const { data } = await axios.post(
                    `${process.env.REACT_APP_API_URL}/auth/line/callback`,
                    { code }

                );
                //console.log("Response from Backend:", data);

                // เก็บ Token และข้อมูลผู้ใช้ลง Local Storage
                localStorage.setItem("token", data.access_token);
                localStorage.setItem("user", JSON.stringify(data.user));

                // อัปเดตสถานะการ Login
                setIsAuthenticated(true);

                // ส่งผู้ใช้ไปหน้า /me
                navigate("/booking");
            } catch (err) {
                console.error("Error during LINE login callback:", err.response);
                setError("Login failed. Please try again.");
            }
        } else {
            setError("Authorization code is missing.");
        }
    };

    useEffect(() => {
        handleLineCallback();
    }, []);

    return error ? <div>{error}</div> : <div>Loading...</div>;
};

export default Callback;