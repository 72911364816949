import React, {useEffect, useState} from "react";
import {Route, Routes, BrowserRouter, Navigate} from "react-router-dom";
import Home from "./pages/Home";
import Price from "./pages/Price";
import Location from "./pages/Location";
import Rules from "./pages/Rules";
import Booking from "./pages/Booking";
import Header from "./components/Header";
import MobileMenu from "./components/MobileMenu";
import Login from "./components/Login";
import ProtectedRoute from "./components/ProtectedRoute";
import Callback from "./pages/Callback";

const App = () => {
    const [darkMode, setDarkMode] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem("token"));

    useEffect(() => {
        if (localStorage.getItem("darkMode") === "true") {
            setDarkMode(true);
        } else {
            setDarkMode(false);
        }
    }, []);

    return (
        <div className={darkMode ? "dark" : ""}>
            <div className="min-h-screen bg-gray-100 dark:bg-gray-900 dark:text-gray-100 transition duration-300">
                <BrowserRouter>
                    <Header darkMode={darkMode} setDarkMode={setDarkMode}/>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/price" element={<Price />} />
                        <Route path="/location" element={<Location />} />
                        <Route path="/rules" element={<Rules />} />
                        <Route
                            path="/booking"
                            element={
                                <ProtectedRoute isAuthenticated={isAuthenticated}>
                                    <Booking />
                                </ProtectedRoute>
                            }
                        />
                        <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
                        <Route path="/callback" element={<Callback setIsAuthenticated={setIsAuthenticated} />} />
                        <Route path="/logout" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
                        <Route path="*" element={<Navigate to="/home" />} />
                    </Routes>
                    <MobileMenu darkMode={darkMode} setDarkMode={setDarkMode} />
                </BrowserRouter>
            </div>
        </div>
    );
};

export default App;