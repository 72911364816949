import React from "react";
import CalendarHeatmap from "./CalendarHeatmap";

const Home = () => (
    <div className="bg-gradient-to-b from-gray-50 to-gray-200 dark:from-gray-800 dark:to-gray-900 min-h-screen">
        {/* Hero Section */}
        <section className="bg-white dark:bg-gray-800 shadow-lg py-16 rounded-b-3xl">
            <div className="max-w-6xl mx-auto px-6 md:px-12 text-center">
                <h1 className="text-4xl sm:text-5xl font-extrabold text-gray-800 dark:text-gray-200">
                    ยินดีต้อนรับสู่{" "}
                    <span className="text-transparent bg-clip-text bg-gradient-to-r from-orange-500 to-pink-500">
                        Staycool
                    </span>
                </h1>
                <div className="flex justify-center mt-8">
                    <img
                        src="/logo.png"
                        alt="Dog Hotel Logo"
                        className="w-24 h-24 sm:w-28 sm:h-28 object-contain"
                    />
                </div>
                <p className="mt-6 text-gray-600 dark:text-gray-300 text-lg leading-relaxed">
                    บริการรับฝากเลี้ยงสุนัขที่เน้นการดูแลด้วยใจ ให้ความสุขและความปลอดภัย
                    เหมือนสุนัขของคุณอยู่บ้าน
                </p>
                <button
                    className="mt-8 bg-gradient-to-r from-orange-500 to-pink-500 text-white py-3 px-8 rounded-full text-lg font-medium shadow-lg hover:shadow-2xl transition-transform duration-200 hover:scale-105"
                    onClick={() => alert("Booking Coming Soon!")}
                >
                    เริ่มต้นการจอง
                </button>
            </div>
        </section>

        {/* About Section */}
        <section className="py-20">
            <div className="max-w-6xl mx-auto px-6 md:px-12 grid grid-cols-1 md:grid-cols-2 gap-10 items-center">
                {/* Image */}
                <div className="relative">
                    <div className="absolute inset-0 bg-gradient-to-br from-orange-400 to-pink-500 rounded-lg shadow-lg opacity-30"></div>
                    <img
                        src="/hero2.jpeg"
                        alt="Dog Care"
                        className="relative rounded-lg shadow-lg w-full object-cover"
                    />
                </div>
                {/* Text Content */}
                <div>
                    <h2 className="text-3xl sm:text-4xl font-bold text-gray-800 dark:text-gray-200 leading-tight">
                        ทำไมต้องเลือก{" "}
                        <span className="text-orange-500">Staycool?</span>
                    </h2>
                    <p className="mt-4 text-gray-600 dark:text-gray-300 text-lg leading-relaxed">
                        เราให้ความสำคัญกับการดูแลสุนัขทุกตัวเหมือนสมาชิกในครอบครัว
                        พร้อมพื้นที่สะอาด ปลอดภัย ไม่ขังกรง และพนักงานที่มีประสบการณ์
                    </p>
                    <ul className="mt-6 space-y-4 text-gray-600 dark:text-gray-400">
                        <li className="flex items-center">
                            <span className="w-4 h-4 bg-orange-500 rounded-full mr-3"></span>
                            ห้องพักสะอาด มีพื้นที่กว้างขวาง ไม่อึดอัด
                        </li>
                        <li className="flex items-center">
                            <span className="w-4 h-4 bg-orange-500 rounded-full mr-3"></span>
                            มีสนามหญ้าให้สุนัขเล่นและออกกำลังกาย
                        </li>
                        <li className="flex items-center">
                            <span className="w-4 h-4 bg-orange-500 rounded-full mr-3"></span>
                            ทีมงานผู้เชี่ยวชาญดูแลตลอด 24 ชั่วโมง
                        </li>
                    </ul>
                </div>
            </div>
        </section>

        {/* Testimonial Section */}
        <section className="bg-gray-100 dark:bg-gray-700 py-20">
            <div className="max-w-6xl mx-auto px-6 md:px-12 text-center">
                <h2 className="text-3xl sm:text-4xl font-bold text-gray-800 dark:text-gray-200">
                    เสียงจากลูกค้า
                </h2>
                <p className="mt-4 text-gray-600 dark:text-gray-300 text-lg">
                    ความประทับใจจากลูกค้าที่ไว้วางใจ Staycool
                </p>
                <div className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-8">
                    {/* Testimonial Card */}
                    {[
                        {
                            text: "สะอาด ร่มรื่น มีหลากแบบตามพฤติกรรมของน้อง...",
                            author: "คุณสมชาย",
                        },
                        {
                            text: "ฝากน้อง 3 วันใส่ใจน้องมากๆ ทางร้านส่งรูปและวิดีโอ...",
                            author: "คุณอารีย์",
                        },
                        {
                            text: "บ้านน่ารักมากกกก อากาศดี๊ดี สถานที่ร่มรื่น...",
                            author: "คุณมาลี",
                        },
                    ].map((item, index) => (
                        <div
                            key={index}
                            className="bg-white dark:bg-gray-800 shadow-lg rounded-xl p-8"
                        >
                            <p className="text-gray-600 dark:text-gray-300 italic">
                                "{item.text}"
                            </p>
                            <p className="mt-6 text-gray-800 dark:text-gray-100 font-medium">
                                - {item.author}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </section>

        {/* Calendar */}
        <section className="bg-gray-100 dark:bg-gray-700 py-20">
            <div className="max-w-6xl mx-auto px-6 md:px-12 text-center">
                <h2 className="text-3xl sm:text-4xl font-bold text-gray-800 dark:text-gray-200">
                    ตารางการจอง
                </h2>
                <p className="mt-4 text-gray-600 dark:text-gray-300 text-lg">
                    ตรวจสอบว่ามีห้องพักว่างในวันที่คุณต้องการ
                </p>
                <div className="mt-10 mx-auto max-w-lg">
                    <CalendarHeatmap />
                </div>
            </div>
        </section>
    </div>
);

export default Home;