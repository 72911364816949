import React, { useState, useContext } from "react";
import { ThemeContext } from "../context/ThemeContext"; // Import ThemeContext

const Price = () => {
    const [activeTab, setActiveTab] = useState("boarding");
    const { darkMode } = useContext(ThemeContext); // ใช้ Context เพื่อดึงค่าธีม

    const priceDetails = [
        {
            type: "สุนัขพันธุ์เล็ก",
            fullDay: "500 บาท",
            halfDay: "300 บาท",
            description: "เหมาะสำหรับสุนัขขนาดเล็ก เช่น ชิวาวา, ปอมเมอเรเนียน",
        },
        {
            type: "สุนัขพันธุ์กลาง",
            fullDay: "700 บาท",
            halfDay: "400 บาท",
            description: "เหมาะสำหรับสุนัขขนาดกลาง เช่น บีเกิล, ค็อกเกอร์ สแปเนียล",
        },
        {
            type: "สุนัขพันธุ์ใหญ่",
            fullDay: "1,000 บาท",
            halfDay: "600 บาท",
            description: "เหมาะสำหรับสุนัขขนาดใหญ่ เช่น โกลเด้น รีทรีฟเวอร์, ลาบราดอร์",
        },
        {
            type: "สุนัขพันธุ์ใหญ่พิเศษ",
            fullDay: "1,500 บาท",
            halfDay: "900 บาท",
            description: "เหมาะสำหรับสุนัขขนาดใหญ่มาก เช่น อลาสกัน มาลามิวท์, เกรทเดน",
        },
    ];

    const petTaxiDetails = {
        pricePerKm: "30 บาท",
        description: "บริการรับส่งสัตว์เลี้ยงได้ทุกที่ คิดราคาตามระยะทาง กิโลเมตรละ 30 บาท",
    };

    return (
        <div
            className={`p-6 rounded-lg max-w-5xl mx-auto mt-10 shadow-lg transition-colors ${
                darkMode ? "bg-gray-900 text-white" : "bg-gray-50 text-gray-900"
            }`}
        >
            {/* Header */}
            <div className="flex justify-center mb-6">
                <img
                    src="/logo.png"
                    alt="Dog Hotel Logo"
                    className="w-24 h-24 object-contain"
                />
            </div>
            <h2 className="text-3xl font-bold text-center mb-4">บริการและราคา</h2>
            <p className="text-center mb-8">
                บริการหลากหลายสำหรับสัตว์เลี้ยงของคุณ
            </p>

            {/* Tabs */}
            <div className="flex justify-center mb-6">
                <button
                    className={`px-4 py-2 rounded-t-lg border-b-2 ${
                        activeTab === "boarding"
                            ? "border-green-500 text-green-600"
                            : darkMode
                                ? "border-gray-700 text-gray-400"
                                : "border-gray-300 text-gray-500"
                    } focus:outline-none`}
                    onClick={() => setActiveTab("boarding")}
                >
                    ตารางแสดงราคา
                </button>
                <button
                    className={`px-4 py-2 rounded-t-lg border-b-2 ml-2 ${
                        activeTab === "petTaxi"
                            ? "border-green-500 text-green-600"
                            : darkMode
                                ? "border-gray-700 text-gray-400"
                                : "border-gray-300 text-gray-500"
                    } focus:outline-none`}
                    onClick={() => setActiveTab("petTaxi")}
                >
                    ราคา Pet Taxi
                </button>
            </div>

            {/* Tab Content */}
            {activeTab === "boarding" && (
                <div>
                    <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4">
                        {priceDetails.map((item, index) => (
                            <div
                                key={index}
                                className={`rounded-lg shadow-md p-5 border transition-all ${
                                    darkMode
                                        ? "bg-gray-800 border-gray-700 hover:border-green-500"
                                        : "bg-white border-gray-200 hover:border-green-500"
                                }`}
                            >
                                <h3 className="text-lg font-semibold text-green-600 mb-3">
                                    {item.type}
                                </h3>
                                <div className="mb-4">
                                    <p>
                                        <span className="font-medium">ราคาเต็มวัน:</span>{" "}
                                        {item.fullDay}
                                    </p>
                                    <p>
                                        <span className="font-medium">ราคาครึ่งวัน:</span>{" "}
                                        {item.halfDay}
                                    </p>
                                </div>
                                <p className="text-sm">{item.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {activeTab === "petTaxi" && (
                <div
                    className={`rounded-lg shadow-md p-5 text-center transition-all ${
                        darkMode
                            ? "bg-gray-800 border-gray-700"
                            : "bg-white border-gray-200"
                    }`}
                >
                    <h3 className="text-lg font-semibold text-green-600 mb-3">
                        บริการ Pet Taxi
                    </h3>
                    <p>
                        <span className="font-medium">ราคา:</span>{" "}
                        {petTaxiDetails.pricePerKm}
                    </p>
                    <p className="text-sm mt-3">{petTaxiDetails.description}</p>
                </div>
            )}
        </div>
    );
};

export default Price;
