import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Room from "./bookings/Room";
import Taxi from "./bookings/Taxi";
import Bath from "./bookings/Bath";
import Swim from "./bookings/Swim";
import Logout from "../components/Logout";

const Booking = () => {
    const [activeTab, setActiveTab] = useState("room");
    const [isAuthenticated, setIsAuthenticated] = useState(true);

    const renderContent = () => {
        switch (activeTab) {
            case "room":
                return <Room />;
            case "taxi":
                return <Taxi />;
            case "bath":
                return <Bath />;
            case "swim":
                return <Swim />;
            default:
                return null;
        }
    };

    return (
        <div className="min-h-screen bg-gray-50 my-5">
            <Navbar activeTab={activeTab} setActiveTab={setActiveTab} />
            <div className="max-w-6xl mx-auto px-6 md:px-12 py-10">
                {renderContent()}
            </div>
            <Logout setIsAuthenticated={setIsAuthenticated} />
        </div>
    );
};

export default Booking;