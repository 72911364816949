import React, { useContext } from "react";
import { ThemeContext } from "../context/ThemeContext"; // Import ThemeContext

const Location = () => {
    const homeLocation = {
        lat: 13.844258,
        lng: 100.370481,
        address: "Staycool รับฝากเลี้ยงสุนัข",
    };

    const { darkMode } = useContext(ThemeContext); // ใช้ Context เพื่อดึงค่าธีม

    return (
        <div
            className={`p-6 rounded-lg max-w-3xl mx-auto mt-10 shadow-lg transition-colors ${
                darkMode ? "bg-gray-900 text-white" : "bg-white text-gray-800"
            }`}
        >
            {/* Logo */}
            <div className="flex justify-center mb-6">
                <img
                    src="/logo.png"
                    alt="Dog Hotel Logo"
                    className="w-24 h-24 object-contain"
                />
            </div>

            {/* Header */}
            <h1 className="text-3xl font-semibold text-center">{homeLocation.address}</h1>
            <p className="text-center mt-2">
                อยู่ซอยร่วมใจพัฒนา ตรงข้าม 7/11 หมู่บ้าน อนาสิริ บางแม่นาง บางใหญ่ นนทบุรี เข้ามาสุดซอย ขวามือ
            </p>
            <p className="text-center mt-2">หรือ ติดต่อ 085 - 996 - 5443</p>

            {/* Static Map */}
            <div className="mt-6 rounded-lg overflow-hidden shadow-md">
                <img
                    src={`https://maps.googleapis.com/maps/api/staticmap?center=${homeLocation.lat},${homeLocation.lng}&zoom=16&size=800x400&markers=color:red%7C${homeLocation.lat},${homeLocation.lng}&key=AIzaSyBeYTEvbWPdAhcrgVr9NctWCd6KsL8e4Ao`}
                    alt="Google Map Location"
                    className="w-full h-auto"
                />
            </div>

            {/* Google Maps Button */}
            <div className="mt-6 flex justify-center">
                <button
                    className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-6 rounded-lg shadow-md transition duration-200"
                    onClick={() =>
                        window.open(
                            `https://www.google.com/maps?q=${homeLocation.lat},${homeLocation.lng}`,
                            "_blank"
                        )
                    }
                >
                    เปิดใน Google Maps
                </button>
            </div>
        </div>
    );
};

export default Location;
