import React, { useState } from "react";

const Room = () => {
    const [checkInDate, setCheckInDate] = useState("");
    const [checkInTime, setCheckInTime] = useState("");
    const [checkOutDate, setCheckOutDate] = useState("");
    const [checkOutTime, setCheckOutTime] = useState("");

    return (
        <div className="p-6 bg-white shadow-lg rounded-lg">
            <h2 className="text-3xl font-bold text-blue-600 mb-4">
                จองห้องพัก
            </h2>
            <p className="text-gray-600 text-lg mb-6">
                เลือกวันที่และเวลาที่เหมาะกับน้องหมาของคุณ
            </p>
            <form className="space-y-4">
                {/* Check-in Date */}
                <div>
                    <label className="block text-gray-700 font-medium">
                        วันที่เข้าพัก
                    </label>
                    <input
                        type="date"
                        className="w-full mt-2 p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
                        value={checkInDate}
                        onChange={(e) => setCheckInDate(e.target.value)}
                    />
                </div>

                {/* Check-in Time */}
                <div>
                    <label className="block text-gray-700 font-medium">
                        เวลาเข้าพัก
                    </label>
                    <input
                        type="time"
                        className="w-full mt-2 p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
                        value={checkInTime}
                        onChange={(e) => setCheckInTime(e.target.value)}
                    />
                </div>

                {/* Check-out Date */}
                <div>
                    <label className="block text-gray-700 font-medium">
                        วันที่ออกพัก
                    </label>
                    <input
                        type="date"
                        className="w-full mt-2 p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
                        value={checkOutDate}
                        onChange={(e) => setCheckOutDate(e.target.value)}
                    />
                </div>

                {/* Check-out Time */}
                <div>
                    <label className="block text-gray-700 font-medium">
                        เวลาออกพัก
                    </label>
                    <input
                        type="time"
                        className="w-full mt-2 p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
                        value={checkOutTime}
                        onChange={(e) => setCheckOutTime(e.target.value)}
                    />
                </div>
            </form>
            <button className="mt-6 bg-gradient-to-r from-blue-500 to-teal-500 text-white px-6 py-3 rounded-lg shadow hover:scale-105 hover:shadow-lg transition duration-200">
                ยืนยันการจอง
            </button>
        </div>
    );
};

export default Room;