import React from "react";

const Navbar = ({ activeTab, setActiveTab }) => {
    const tabs = [
        { label: "จองห้อง", key: "room" },
        { label: "Taxi", key: "taxi" },
        { label: "อาบน้ำ", key: "bath" },
        { label: "ว่ายน้ำ", key: "swim" },
    ];

    return (
        <div className="bg-white shadow-md sticky top-0 z-10">
            <div className="max-w-6xl mx-auto px-6 md:px-12 flex flex-wrap items-center justify-between">
                {tabs.map((tab) => (
                    <button
                        key={tab.key}
                        onClick={() => setActiveTab(tab.key)}
                        className={`py-2 px-2.5 rounded-lg text-md font-medium transition ${
                            activeTab === tab.key
                                ? "bg-gradient-to-r from-blue-500 to-teal-500 text-white shadow-lg"
                                : "text-gray-600 hover:bg-gray-200"
                        }`}
                    >
                        {tab.label}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default Navbar;