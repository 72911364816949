import React, {useContext} from 'react';
import {Link} from "react-router-dom";
import { SunIcon, MoonIcon } from '@heroicons/react/24/outline';
import {ThemeContext} from "../context/ThemeContext";

const Header = () => {
    // ดึงค่า darkMode และ setDarkMode จาก ThemeContext
    const { darkMode, toggleTheme } = useContext(ThemeContext);

    return (
        <nav className="flex justify-between items-center px-4 py-2 bg-gray-200 dark:bg-gray-800 shadow-md">
            {/* Logo */}
            <div className="flex items-center">
                <img
                    src="/logo.png" // ใส่โลโก้ในโฟลเดอร์ public เช่น public/logo.png
                    alt="Dog Hotel Logo"
                    className="w-10 h-10 mr-2"
                />
                <h1 className="text-2xl font-bold">Staycool</h1>
            </div>
            <ul className="hidden md:flex gap-4">
                <Link to="/price" aria-label="Go to About">ราคา</Link>
                <Link to="/location" aria-label="Go to Project">สถานที่</Link>
                <Link to="/rules" aria-label="Go to Contact">ข้อกำหนด</Link>
                <Link to="/booking" aria-label="Go to Me"> จองห้องพัก </Link>
            </ul>
            <button onClick={toggleTheme} className="p-2 rounded-full" aria-label="Theme">
                {darkMode ? <SunIcon className="h-6 w-6 text-yellow-400"/> :
                    <MoonIcon className="h-6 w-6 text-gray-800"/>}
            </button>
        </nav>
    );
};

export default Header;