import React, { useContext } from "react";
import { ThemeContext } from "../context/ThemeContext"; // Import ThemeContext

const Rules = () => {
    const { darkMode } = useContext(ThemeContext); // ใช้ Context เพื่อดึงค่าธีม

    return (
        <div
            className={`p-6 md:p-10 max-w-3xl mx-auto shadow-lg rounded-lg mt-10 transition-colors ${
                darkMode ? "bg-gray-900 text-white" : "bg-white text-gray-800"
            }`}
        >
            {/* Header */}
            <h2 className="text-3xl md:text-4xl font-bold text-center mb-8">
                Staycool Dog Hotel
            </h2>
            <p className="text-center text-gray-500 dark:text-gray-300 mb-6">
                ข้อกำหนดและสิ่งที่ต้องเตรียมเมื่อเข้าพัก
            </p>

            {/* Rules List */}
            <ul className="space-y-6">
                {[
                    {
                        title: "เตรียมอาหารและขนมให้เพียงพอ:",
                        description:
                            "แนะนำให้งดเปลี่ยนอาหารเพื่อป้องกันสุนัขไม่ยอมรับอาหาร หากไม่สามารถเตรียมได้ ค่าบริการเพิ่ม 50 บาท/มื้อ",
                    },
                    {
                        title: "ของเล่นที่น้องชอบ:",
                        description: "เพื่อให้น้องรู้สึกผ่อนคลายและคุ้นเคยกับที่พัก",
                    },
                    {
                        title: "สายจูง:",
                        description: "กรุณาเตรียมสายจูงที่เหมาะสมกับขนาดตัวของสุนัข",
                    },
                    {
                        title: "โอ๊บ หรือ แผ่นเพดส์:",
                        description:
                            "เตรียมไว้ให้เพียงพอสำหรับจำนวนวันที่พัก (อย่างน้อย 2-4 ชิ้นต่อวัน) หากไม่มีมา ค่าบริการเพิ่ม 50 บาท/แผ่น",
                    },
                    {
                        title: "ที่ชุบน้ำและสบู่อาบน้ำ:",
                        description:
                            "เตรียมไว้สำหรับกรณีที่สุนัขเล่นเปื้อนและต้องการทำความสะอาด",
                    },
                    {
                        title: "สมุดวัคซีนของสุนัข:",
                        description:
                            "สุนัขต้องได้รับวัคซีนครบและอยู่ในสภาพสุขภาพดี ไม่มีโรคติดต่อ",
                    },
                ].map((item, index) => (
                    <li
                        key={index}
                        className={`p-4 rounded-lg border transition ${
                            darkMode
                                ? "bg-gray-800 border-gray-700"
                                : "bg-gray-50 border-green-200"
                        }`}
                    >
            <span
                className={`block font-bold ${
                    darkMode ? "text-green-400" : "text-green-600"
                }`}
            >
              {item.title}
            </span>
                        <p className="mt-1 text-sm text-gray-600 dark:text-gray-300">
                            {item.description}
                        </p>
                    </li>
                ))}
            </ul>

            {/* Contact Section */}
            <div className="mt-8 text-center">
                <p className="text-gray-500 dark:text-gray-300 text-sm">
                    สนใจติดต่อ:{" "}
                    <span
                        className={`font-semibold ${
                            darkMode ? "text-green-400" : "text-green-500"
                        }`}
                    >
            line@staycool239
          </span>{" "}
                    หรือโทร{" "}
                    <span
                        className={`font-semibold ${
                            darkMode ? "text-green-400" : "text-green-500"
                        }`}
                    >
            085-996-5443
          </span>
                </p>
            </div>
        </div>
    );
};

export default Rules;
