import { useNavigate } from "react-router-dom";

export default function Logout() {
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem("token"); // ลบ token
        localStorage.removeItem("user"); // ลบ user

        navigate("/login"); // Redirect ไปหน้า Login
    };

    return (
        <button
            onClick={handleLogout}
            className="px-4 py-2 bg-red-500 text-white rounded-md shadow-md hover:bg-red-600 transition"
        >
            Logout
        </button>
    );
}
