import React from 'react';
import { HomeIcon, CurrencyDollarIcon, CalendarIcon, MapPinIcon, DocumentTextIcon } from '@heroicons/react/24/outline';

const MobileMenu = () => {
    return (
        <nav className="fixed bottom-0 left-0 w-full bg-gray-200 dark:bg-gray-800 shadow-md md:hidden rounded-t-2xl">
            <ul className="flex justify-between items-center px-6 py-3 relative">
                {/* Home */}
                <li className="group">
                    <a href="/" className="flex flex-col items-center" aria-label="Go to Home">
                        <HomeIcon
                            className="h-6 w-6 text-gray-600 dark:text-gray-200 group-hover:text-yellow-400 transition-transform transform group-hover:scale-125"
                        />
                        <span className="text-sm text-gray-600 dark:text-gray-200 group-hover:text-yellow-400">Home</span>
                    </a>
                </li>

                {/* Price */}
                <li className="group">
                    <a href="/price" className="flex flex-col items-center" aria-label="Go to Pricing">
                        <CurrencyDollarIcon
                            className="h-6 w-6 text-gray-600 dark:text-gray-200 group-hover:text-yellow-400 transition-transform transform group-hover:scale-125"
                        />
                        <span className="text-sm text-gray-600 dark:text-gray-200 group-hover:text-yellow-400">ราคา</span>
                    </a>
                </li>

                {/* เพิ่มพื้นที่ว่าง */}
                <div className="w-6"></div>

                {/* Icon ตรงกลาง */}
                <li className="absolute -top-8 left-1/2 transform -translate-x-1/2 flex flex-col items-center">
                    <div
                        className="bg-yellow-400 w-14 h-14 rounded-full flex items-center justify-center shadow-lg group hover:scale-110 transition-transform">
                        <a href="/booking" aria-label="Go to Booking" className="flex items-center justify-center">
                            <CalendarIcon
                                className="h-7 w-7 text-white group-hover:scale-125 transition-transform transform"
                            />
                        </a>
                    </div>
                    <span className="text-sm text-gray-600 dark:text-gray-200 mt-2">Booking</span>
                </li>

                {/* Location */}
                <li className="group">
                    <a href="/location" className="flex flex-col items-center" aria-label="Go to Location">
                        <MapPinIcon
                            className="h-6 w-6 text-gray-600 dark:text-gray-200 group-hover:text-yellow-400 transition-transform transform group-hover:scale-125"
                        />
                        <span className="text-sm text-gray-600 dark:text-gray-200 group-hover:text-yellow-400">สถานที่</span>
                    </a>
                </li>

                {/* Rules */}
                <li className="group">
                    <a href="/rules" className="flex flex-col items-center" aria-label="Go to Rules">
                        <DocumentTextIcon
                            className="h-6 w-6 text-gray-600 dark:text-gray-200 group-hover:text-yellow-400 transition-transform transform group-hover:scale-125"
                        />
                        <span className="text-sm text-gray-600 dark:text-gray-200 group-hover:text-yellow-400">ข้อกำหนด</span>
                    </a>
                </li>
            </ul>
        </nav>
    );
};

export default MobileMenu;