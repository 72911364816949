import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../context/ThemeContext"; // Import Context

const Login = () => {
    const [userData, setUserData] = React.useState(null);
    const [error, setError] = React.useState(null);
    const navigate = useNavigate();
    const { darkMode } = useContext(ThemeContext); // ใช้งาน Context

    React.useEffect(() => {
        if (userData) {
            navigate("/booking");
        }
    }, [userData, navigate]);

    return (
        <div className={`h-screen flex items-center justify-center transition-colors ${darkMode ? "bg-gray-900" : "bg-gray-100"}`}>
            <div
                className={`p-6 rounded-lg shadow-lg text-center w-80 ${darkMode ? "bg-gray-800 text-white" : "bg-white text-gray-900"}`}>
                <h1 className="text-xl font-bold mb-4">Staycool Login</h1>
                <img
                    src="/logo.png"
                    alt="Dog Hotel Logo"
                    className="w-24 h-24 object-contain mx-auto mb-4"
                />

                {!userData && (
                    <a
                        href={`${process.env.REACT_APP_API_URL}/auth/line/redirect`}
                        className="flex items-center justify-center w-full px-4 py-2 bg-green-500 text-white rounded-md shadow-md hover:bg-green-600 transition"
                    >
                        <img
                            src="https://upload.wikimedia.org/wikipedia/commons/4/41/LINE_logo.svg"
                            alt="LINE"
                            className="h-5 w-5 mr-2"
                        />
                        Login with LINE
                    </a>
                )}

                {error && <p className="text-red-500 mt-4">{error}</p>}

                <div className="mt-4">
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                        By logging in, you agree to our <a href="/terms" className="text-blue-500 hover:underline">Terms
                        of Service</a>.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Login;
