import React, { useEffect, useState, useContext } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import axios from "axios";
import { ThemeContext } from "../context/ThemeContext"; // ใช้ ThemeContext
import "./CalendarHeatmap.css";


const CalendarHeatmap = () => {
    const { darkMode } = useContext(ThemeContext); // ดึงค่าธีม
    const [heatmapData, setHeatmapData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // ดึงข้อมูลจาก API
        const fetchHeatmapData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/heatmap-data`
                );
                const formattedData = formatData(response.data);
                setHeatmapData(formattedData);
                setLoading(false);
            } catch (err) {
                console.error("Error fetching heatmap data:", err);
                setError("ไม่สามารถโหลดข้อมูลได้ กรุณาลองใหม่อีกครั้ง");
                setLoading(false);
            }
        };

        fetchHeatmapData();
    }, []);

    const formatData = (data) => {
        // แปลงข้อมูลเป็น key-value format {"YYYY-MM-DD": count}
        return data.reduce((acc, item) => {
            acc[item.date] = item.count;
            return acc;
        }, {});
    };

    const getTileClassName = ({ date, view }) => {
        // เพิ่ม className ให้กับวันที่แต่ละช่องตามความหนาแน่น
        if (view === "month") {
            const dateString = date.toISOString().split("T")[0]; // แปลงวันที่เป็น YYYY-MM-DD
            const count = heatmapData[dateString] || 0; // ถ้าไม่มีข้อมูลให้ค่าเป็น 0
            if (count > 15) return "heatmap-red";
            if (count > 10) return "heatmap-orange";
            if (count > 5) return "heatmap-yellow";
            if (count > 0) return "heatmap-green";
        }
        return "";
    };

    return (
        <div
            className={`p-6 md:p-10 max-w-3xl mx-auto shadow-lg rounded-lg mt-10 transition-colors ${
                darkMode ? "bg-gray-900 text-white" : "bg-white text-gray-800"
            }`}
        >
            {/* Header */}
            <h2 className="text-2xl md:text-3xl font-bold text-center mb-6">
                ความหนาแน่นของการจอง
            </h2>
            {loading ? (
                <p className="text-center text-gray-600 dark:text-gray-300">
                    กำลังโหลดข้อมูล...
                </p>
            ) : error ? (
                <p className="text-center text-red-500">{error}</p>
            ) : (
                <div className="flex justify-center">
                    <Calendar tileClassName={getTileClassName} />
                </div>
            )}

            {/* Heatmap Legend */}
            {!loading && !error && (
                <div className="mt-6 flex justify-center space-x-4 text-sm">
                    <div className="flex items-center">
                        <div className="w-4 h-4 bg-heatmap-green rounded mr-2"></div>
                        <span>0-5</span>
                    </div>
                    <div className="flex items-center">
                        <div className="w-4 h-4 bg-heatmap-yellow rounded mr-2"></div>
                        <span>6-10</span>
                    </div>
                    <div className="flex items-center">
                        <div className="w-4 h-4 bg-heatmap-orange rounded mr-2"></div>
                        <span>11-15</span>
                    </div>
                    <div className="flex items-center">
                        <div className="w-4 h-4 bg-heatmap-red rounded mr-2"></div>
                        <span>15+</span>
                    </div>
                </div>
            )}

            <style jsx>{`
                .heatmap-green {
                    background-color: #8fbc8f !important;
                }
                .heatmap-yellow {
                    background-color: #ffeb3b !important;
                }
                .heatmap-orange {
                    background-color: #ffa726 !important;
                }
                .heatmap-red {
                    background-color: #f44336 !important;
                }
                .react-calendar__tile {
                    border-radius: 6px;
                    transition: all 0.3s ease;
                }
                .react-calendar__tile:hover {
                    transform: scale(1.05);
                }
            `}</style>
        </div>
    );
};

export default CalendarHeatmap;
