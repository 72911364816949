import React from "react";

const Taxi = () => (
    <div className="p-6 bg-white shadow-lg rounded-lg">
        <h2 className="text-3xl font-bold text-blue-600 mb-4">
            บริการ Pet Taxi
        </h2>
        <p className="text-gray-600 text-lg mb-6">
            บริการรับ-ส่งน้องหมาในราคา 30 บาท/กม. พร้อมคนดูแลอย่างดี
        </p>
        <button className="bg-gradient-to-r from-orange-500 to-pink-500 text-white px-6 py-3 rounded-lg shadow hover:scale-105 hover:shadow-lg transition duration-200">
            ติดต่อเรา
        </button>
    </div>
);

export default Taxi;